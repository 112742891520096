import merge from 'lodash/merge';
import { color } from './constants';

const defaultTheme = {
  gutter: 16,
  breakpoints: {
    small: '32em',
    medium: '48em',
    large: '64em',
  },
  color: color.textDefault,
  link: { color: color.digitalBlue },
  header: { color: color.textDefault },
  footer: { color: color.textDefault },
};

export default style => {
  return merge(defaultTheme, style, {
    baseColor: style.baseColor,
    color: style.textColor,
    link: { color: style.linkTextColor },
    button: {
      color: style.buttonTextColor,
      backgroundColor: style.buttonBgColorTop,
      borderRadius: style.buttonBorderRadius,
      topBackgroundColor: style.buttonBgColorTop,
      bottomBackgroundColor: style.buttonBgColorBottom,
      flat: style.buttonBgColorTop,
    },
    tab: {
      color: style.textColor,
      activeColor: style.buttonBgColorTop,
      dividerColor: style.innerBoxBgColor,
    },
    header: {
      logo: style.headerLogoUrl,
      backgroundColor: style.headerBgColor,
      color: style.headerTextColor,
      backgroundImage: style.headerBgImage,
    },
    heading: {
      color: style.boxTitleBgTop === style.bodyBgColor ? style.boxTitleTextColor : style.textColor,
      backgroundColor:
        style.boxTitleBgTop === style.bodyBgColor ? style.boxTitleBgTop : 'transparent',
    },
    footer: {
      backgroundColor: style.footerBgColor,
      color: style.footerTextColor,
      backgroundImage: style.footerBgImage,
    },
    content: {
      backgroundColor: style.bodyBgColor,
      innerBoxBgColor: style.innerBoxBgColor,
      backgroundImage: style.bodyBgImage,
      backgroundAttachment: style.contentBgAttachment,
      color: style.textColor,
    },
    productItem: {
      color: style.productNameTextColor,
    },
    advantageColor: style.advantageColor,
    message: {
      infoColor: style.messageInfoTitleColor,
      successColor: style.messageSuccessTitleColor,
      warningColor: style.messageWarningTitleColor,
      errorColor: style.messageErrorTitleColor,
    },
    currentTheme: style.theme,
  });
};
