import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import FormField from '../../../form-field';
import Input from '../../../input';
import ContactCriterionField from './ContactCriterionField';
import ContactAuthorizationField from './ContactAuthorizationField';
import { contactCriteriaType, contactAuthorizationType } from './type';

const Fields = ({ activeFields, civilityField, criteria, authorizations, fields, updateState }) => (
  <div>
    {civilityField}
    {activeFields.map(fieldCode => (
      <FormField
        mandatory
        label={<FormattedHTMLMessage id={`widget.contactCreation.field.${fieldCode}`} />}
        fieldId={fieldCode}
        key={fieldCode}>
        <Input
          type="text"
          id={fieldCode}
          name={fieldCode}
          validationErrorMsg={fields[fieldCode].errorMsg}
          value={fields[fieldCode].value}
          onChange={updateState(fieldCode)}
        />
      </FormField>
    ))}

    <FormField
      mandatory
      label={<FormattedHTMLMessage id="widget.contactCreation.field.email" />}
      fieldId="email">
      <Input
        id="email"
        type="email"
        name="email"
        validationErrorMsg={fields.email.errorMsg}
        value={fields.email.value}
        onChange={updateState('email')}
      />
    </FormField>
    {Object.keys(criteria).map(
      criterionIdCode =>
        !criteria[criterionIdCode].invisible && (
          <ContactCriterionField
            key={criterionIdCode}
            contactCriterionDefinition={criteria[criterionIdCode]}
            values={criteria[criterionIdCode].value}
            onChange={updateState(`criteria.${criterionIdCode}`)}
          />
        ),
    )}
    {Object.keys(authorizations).map(
      authorizationCode =>
        !authorizations[authorizationCode].invisible && (
          <ContactAuthorizationField
            key={authorizationCode}
            contactAuthorizationDefinition={authorizations[authorizationCode]}
            value={authorizations[authorizationCode].value}
            onChange={updateState(`authorization.${authorizationCode}`)}
          />
        ),
    )}
  </div>
);

Fields.propTypes = {
  activeFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  civilityField: PropTypes.node.isRequired,
  fields: PropTypes.objectOf(
    PropTypes.shape({
      value: PropTypes.string,
      errorMsg: PropTypes.string,
    }),
  ).isRequired,
  criteria: PropTypes.objectOf(contactCriteriaType).isRequired,
  authorizations: PropTypes.objectOf(contactAuthorizationType).isRequired,
  updateState: PropTypes.func.isRequired,
};

export default Fields;
