import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '../../../icons';
import { ErrorSeverity } from './../constants';

const SEVERITY_ICON_NAME = {};
SEVERITY_ICON_NAME[ErrorSeverity.FATAL] = 'sentiment-very-dissatisfied';
SEVERITY_ICON_NAME[ErrorSeverity.ERROR] = 'error-outline';
SEVERITY_ICON_NAME[ErrorSeverity.WARNING] = 'warning';
SEVERITY_ICON_NAME[ErrorSeverity.INFO] = 'info';
SEVERITY_ICON_NAME[ErrorSeverity.SUCCESS] = 'success';

const SEVERITY_ICON_COLOR = {};
SEVERITY_ICON_COLOR[ErrorSeverity.FATAL] = 'negative';
SEVERITY_ICON_COLOR[ErrorSeverity.ERROR] = 'negative';
SEVERITY_ICON_COLOR[ErrorSeverity.WARNING] = 'warning';
SEVERITY_ICON_COLOR[ErrorSeverity.INFO] = 'info';
SEVERITY_ICON_COLOR[ErrorSeverity.SUCCESS] = 'positive';

const ICON_SIZE = 48;
const ICON_SIZE_WITH_UNIT = `${ICON_SIZE}px`;

const Wrapper = styled.div.attrs({
  className: 'g-Message',
})`
  position: relative;
  padding: 0.5em;
  &::before {
    content: ' ';
    display: inline-block;
    height: ${ICON_SIZE_WITH_UNIT};
    vertical-align: middle;
  }
`;
const IconWrapper = styled.div.attrs({
  className: 'g-Message__icon',
})`
  position: absolute;
  top: 0.5em;
`;

const ContentWrapper = styled.div.attrs({
  className: 'g-Message__content',
})`
  display: inline-block;
  vertical-align: middle;
  margin-left: calc(${ICON_SIZE_WITH_UNIT} + 0.5em);
`;

const Message = ({ severity, children }) => {
  const type = SEVERITY_ICON_COLOR[severity];
  const iconName = SEVERITY_ICON_NAME[severity] || 'info';

  return (
    <Wrapper className={`g-Message--${severity}`}>
      <IconWrapper>
        <Icon size={ICON_SIZE} type={type} iconName={iconName} />
      </IconWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  );
};

Message.propTypes = {
  severity: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]).isRequired,
};

export default Message;
