import axios from 'axios';
import merge from 'lodash/merge';
import getTranslationMessages from './i18n';

async function getConfig(hostname, apiKey, apiURLOverride, locale) {
  const headers = {
    'Content-Type': 'application/json',
    'X-Secutix-Host': hostname,
    'X-API-Key': apiKey,
    'Accept-Language': locale,
  };

  const apiURL = apiURLOverride || `https://${hostname}/tnwr/v1`;
  const configURL = `${apiURL}/config?additionalFields=style,contactTitles,labelOverrides`;

  const response = await axios.get(configURL, { headers });

  const { configuration, labelOverrides } = response.data;
  const { currentLanguage } = configuration;
  const { style } = response.data;
  const { contactTitles } = response.data;
  const language =
    locale || configuration.currentLanguage || configuration.institutionLanguage || 'en';

  const translationMessages = await getTranslationMessages(language);
  const translations = merge(
    translationMessages,
    labelOverrides && labelOverrides.currentTranslations,
  );

  return { configuration, currentLanguage, style, language, translations, contactTitles };
}

export default getConfig;
