import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const fontSize = size => {
  const sizes = {
    small: '1.125rem',
    default: '1.5rem',
    large: '2.25rem',
    xlarge: '3rem'
  }
  let iconSize

  if (typeof size === 'string') {
    iconSize = sizes[size]
  } else {
    iconSize = `${size / 16}rem`
  }

  return iconSize ? iconSize : sizes.default
}

const typeColors = {
  default: 'currentColor',
  info: '#75cdf8',
  positive: '#81dca7',
  notice: '#f8cf7e',
  warning: '#ff8c57',
  negative: '#ff4747'
}

const IconWrapper = styled.span.attrs({
  className: props => `tg-Icon tg-Icon-${props.iconName}`
})`
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  vertical-align: middle;
  fill: currentcolor;
  stroke: none;

  font-size: ${props => props.size && fontSize(props.size)};
  color: currentcolor;
  width: 1em;
  height: 1em;

  transform: translate3d(0,0,0);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  & > svg {
    pointer-events: none;
    display: block;
    width: 100%;
    height: 100%;

    fill: ${props => typeColors[props.type]};
  }
`

const Icon = ({ size, type, ...props }) => {
  const svg = require(`./icons/${props.iconName}.svg`)

  return (
    <IconWrapper size={size} type={type} {...props} dangerouslySetInnerHTML={{ __html: svg }} />
  )
}

Icon.propTypes = {
  iconName: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(['default', 'info', 'positive', 'notice', 'warning', 'negative'])
}

Icon.defaultProps = {
  size: 'small',
  type: 'default'
}

export default Icon
