import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const StyledWrapper = styled.div.attrs({
  className: 'g-FormField'
})`
  padding: 0.5em;
`
const LabelWrapper = styled.label.attrs({
  className: props => `g-FormField__label stx-theme-${props.theme.currentTheme}`
})`
  vertical-align: middle;
  font-size: 0.85em;
  &.stx-theme-EVOLUTION {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
    color: #2F2B43;
  }
`
const ChildWrapper = styled.div.attrs({
  className: 'g-FormField__field'
})`
  margin-top: 0.25em;
  vertical-align: middle;
`

const FormField = props => {
  return (
    <StyledWrapper>
      <LabelWrapper htmlFor={props.fieldId}>
        {props.label}<sup>*</sup>
      </LabelWrapper>
      <ChildWrapper>{props.children}</ChildWrapper>
    </StyledWrapper>
  )
}

FormField.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired,
  fieldId: PropTypes.string,
  mandatory: PropTypes.bool,
  children: PropTypes.any
}

FormField.defaultProps = {
  fieldId: '',
  mandatory: false
}
export default FormField
