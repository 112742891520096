import PropTypes from 'prop-types';

const contactCriteriaType = PropTypes.shape({
  criterionIdCode: PropTypes.string.isRequired,
  dataType: PropTypes.oneOf([
    'INTEGER',
    'PERCENT',
    'DRAGNDROPLIST',
    'CHECKBOX',
    'TEXTAREA',
    'DECIMAL',
    'RADIO',
  ]),
  elements: PropTypes.array,
  invisible: PropTypes.bool,
  criterionType: PropTypes.oneOf(['SIMPLE', 'LIST']),
});

const contactAuthorizationType = PropTypes.shape({
  authorizationCode: PropTypes.string.isRequired,
  // externalName = '',
  dataType: PropTypes.oneOf(['RADIO', 'CHECKBOX']),
  // visibility: PropTypes.oneOf(['NONE', 'READ','UPDATE']),
  // mandatory: PropTypes.bool,
  // rank: PropTypes.number,
  invisible: PropTypes.bool,
});

export { contactCriteriaType, contactAuthorizationType };
