import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { size, uniqueId } from 'lodash';
import FormField from '../../../form-field';
import Input from '../../../input';
import {
  shouldBeDisplayedAsTextInput,
  displayDragNDropWithCheckboxesThreshold,
} from './../constants';

import { contactCriteriaType } from './type';

const isDisplayedAsSimpleTextInput = dataType =>
  shouldBeDisplayedAsTextInput.indexOf(dataType) >= 0;

const ContactCriterionField = ({ contactCriterionDefinition, values, onChange }) => {
  const {
    criterionIdCode,
    dataType = 'RADIO',
    elements = [],
    invisible = true,
    criterionType = 'SIMPLE',
  } = contactCriterionDefinition;

  const compId = uniqueId();
  const yesButtonID = `${compId}-yes`;
  const noButtonID = `${compId}-no`;
  return (
    !invisible && (
      <FormField label={`label.editContactProfile.contactCriteria.${criterionIdCode}`}>
        {isDisplayedAsSimpleTextInput(dataType) && <Input name={criterionIdCode} />}
        {(dataType === 'INTEGER' || dataType === 'PERCENT') && (
          <Input name={criterionIdCode} pattern="[0-9]*" />
        )}
        {dataType === 'DECIMAL' && <Input name={criterionIdCode} pattern="[0-9\.]*" />}
        {dataType === 'TEXTAREA' && <textarea name={criterionIdCode} />}
        {(dataType === 'CHECKBOX' ||
          (dataType === 'DRAGNDROPLIST' ||
            size(elements) < displayDragNDropWithCheckboxesThreshold)) &&
          false // Missing implementation for checkbox
        }
        {dataType === 'RADIO' &&
          criterionType === 'SIMPLE' && (
            // Yes/No question
            <div>
              <input
                id={yesButtonID}
                type="radio"
                name={criterionIdCode}
                value="true"
                checked={values[0] === true}
                onChange={onChange}
              />
              <label htmlFor={yesButtonID}>
                <FormattedHTMLMessage id="cmp.form-field.radio.yes" defaultMessage="Yes" />
              </label>
              <input
                id={noButtonID}
                type="radio"
                name={criterionIdCode}
                value="false"
                checked={values[0] === false}
                onChange={onChange}
              />
              <label htmlFor={noButtonID}>
                <FormattedHTMLMessage id="cmp.form-field.radio.no" defaultMessage="No" />
              </label>
            </div>
          )}
      </FormField>
    )
  );
};

ContactCriterionField.propTypes = {
  contactCriterionDefinition: contactCriteriaType.isRequired,
  values: PropTypes.arrayOf(PropTypes.any),
  onChange: PropTypes.func,
};

ContactCriterionField.defaultProps = {
  values: [true],
  onChange: () => null,
};

export default ContactCriterionField;
