import fetch from 'isomorphic-fetch';
import https from 'https';

let API_CONF = null;

let agent = null;

let standardHeader = null;

const RESOURCES = {};

let authHeader = null;

const commonResponse = response => {
  if (response.status >= 300) {
    return response.json().then(json => {
      throw json;
    });
  }
  authHeader = response.headers.get('x-auth-token') || authHeader;
  return response.json();
};

const buildRequestForResource = resourceName =>
  // buildRequest
  (requestBody, headers = {}) => {
    let requestBodyJson;
    if (typeof requestBody === 'string') {
      requestBodyJson = requestBody;
    } else {
      requestBodyJson = JSON.stringify(requestBody);
    }
    return {
      post: () =>
        fetch(`${API_CONF.apiURL}/${resourceName}`, {
          method: 'POST',
          credentials: 'include',
          headers: Object.assign({ 'x-auth-token': authHeader }, standardHeader, headers),
          body: requestBodyJson,
          agent,
        }).then(commonResponse),
      get: () =>
        fetch(`${API_CONF.apiURL}/${resourceName}`, {
          method: 'GET',
          credentials: 'include',
          headers: Object.assign({ 'x-auth-token': authHeader }, standardHeader, headers),
          agent,
        }).then(commonResponse),
    };
  };

const forResource = resourceName => {
  if (!RESOURCES[resourceName]) {
    RESOURCES[resourceName] = {
      buildRequest: buildRequestForResource(resourceName),
    };
  }
  return RESOURCES[resourceName];
};

const API = {
  configure: config => {
    API_CONF = config;
    standardHeader = {
      'Content-Type': 'application/json',
      'X-Secutix-SecretKey': config.apiKey,
      'X-Secutix-Host': config.pointOfSales,
    };
    if (typeof global.it === 'function') {
      agent = new https.Agent({
        rejectUnauthorized: false,
      });
    }

    return {
      forResource,
    };
  },
};

export default API;
