const ErrorSeverity = {
  FATAL: 'FATAL',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
  SUCCESS: 'SUCCESS',
};

const shouldBeDisplayedAsTextInput = [
  'TEXTINPUT',
  'CITYINPUT',
  'IBAN',
  'COLOR',
  'DATEINPUT',
  'TIME',
  'MONEY',
  'PHONE_NUMBER',
  'IBAN',
  'URL',
];

const displayDragNDropWithCheckboxesThreshold = 12;

export { ErrorSeverity, shouldBeDisplayedAsTextInput, displayDragNDropWithCheckboxesThreshold };
