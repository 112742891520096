import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ContactCreation from './packages/contact-creation/src'

const Wrapper = styled.div.attrs({
  className: 'stx-Widget',
})`
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 13px;
  font-family: 'Open sans', 'Helvetica Neue', Arial, 'Liberation Sans', FreeSans, sans-serif;
  vertical-align: baseline;
  box-sizing: border-box;
  text-align: left;
`;

const defaultCriteria = [
  {
    criterionIdCode: 'NEWSLET',
    defaultValue: [true],
  },
];

const defaultAuthorizations = [
  {
    authorizationCode: 'CNIL_O',
    defaultValue: true,
  },
];
/**
 * Newsletter allow users to subscribe to mailing list by creating contact with corresponding email
 */
class WidgetContainer extends PureComponent {
  state = {
    isLoading: true,
    hasError: false,
    data: null,
  };

  render() {
    const {
      locale,
      contactTitles,
      captchaKey,
      hostname,
      apiKey,
      activeFields,
      contactOrigin,
      initialEmail,
      criteriaFields,
      authorizationFields,
      privacyPolicyURL,
      privacyPolicySummary,
      invisibleCaptcha,
      currentTheme
    } = this.props;

    let apiURL = this.props.apiURL;
    if (!apiURL) {
      apiURL = `https://${hostname}/tnwr/v1`;
    }

    return (
      <Wrapper>
        <ContactCreation
          captchaKey={captchaKey}
          contactTitles={contactTitles}
          apiURL={apiURL}
          apiKey={apiKey}
          language={locale}
          pointOfSales={hostname}
          activeFields={activeFields}
          criteriaFields={defaultCriteria.concat(criteriaFields)}
          authorizationFields={defaultAuthorizations.concat(authorizationFields)}
          invisibleCaptcha={invisibleCaptcha}
          initialEmail={initialEmail}
          contactOrigin={contactOrigin}
          privacyPolicyUrl={privacyPolicyURL}
          privacyPolicySummary={privacyPolicySummary}
          currentTheme={currentTheme}
        />
      </Wrapper>
    );
  }
}

WidgetContainer.propTypes = {
  /**
   * Global config : contains the info on what server we should call
   */
  hostname: PropTypes.string.isRequired,
  /**
   * Global config : Rest API ent point
   */
  apiURL: PropTypes.string,
  /**
   * Global config : contains the value to identify the institution
   */
  apiKey: PropTypes.string.isRequired,
  /**
   * Contains the language in which the widget will be displayed
   */
  locale: PropTypes.string.isRequired,
  /**
   * Captcha API site key (default to SecuTix invisible captcha key)
   */
  captchaKey: PropTypes.string.isRequired,
  /**
   * List of the contact titles selectable on form
   */
  contactTitles: PropTypes.arrayOf(PropTypes.string),
  /*
   * List of field that should be displayed on form ('civility', 'firstName', 'lastName')
   */
  activeFields: PropTypes.arrayOf(PropTypes.string),
  /*
   * List of criteria that should be sent toghether when create contact
   */
  criteria: PropTypes.arrayOf(PropTypes.string),
  /*
   * List of authorizations that should be sent toghether when create contact
   */
  authorizations: PropTypes.arrayOf(PropTypes.string),
  /*
   * The default display value of email
   */
  initialEmail: PropTypes.string,
  /*
   * Contact origin of contact
   */
  contactOrigin: PropTypes.string,
  /*
   * TODO missing info
   */
  criteriaFields: PropTypes.arrayOf(PropTypes.object),
  /*
   * TODO missing info
   */
  authorizationFields: PropTypes.arrayOf(PropTypes.object),

  privacyPolicySummary: PropTypes.string,

  privacyPolicyURL: PropTypes.string,
  invisibleCaptcha: PropTypes.string,
  currentTheme: PropTypes.string,
};

WidgetContainer.defaultProps = {
  apiURL: undefined,
  contactTitles: undefined,
  activeFields: undefined,
  criteria: undefined,
  authorizations: undefined,
  initialEmail: undefined,
  contactOrigin: undefined,
  criteriaFields: [],
  authorizationFields: [],
  privacyPolicySummary: undefined,
  privacyPolicyURL: undefined,
  invisibleCaptcha: undefined,
  currentTheme: 'EQUILIBRIUM'
};

export default WidgetContainer;
