const buildWidgetState = ({ activeFields, initialEmail, criteriaFields, authorizationFields }) => {
  const partialState = {
    criteria: {},
    authorizations: {},
    fields: {},
  };
  criteriaFields.forEach(field => {
    partialState.criteria[field.criterionIdCode] = Object.assign(
      {
        value: field.defaultValue,
      },
      field,
    );
  });
  authorizationFields.forEach(field => {
    partialState.authorizations[field.authorizationCode] = Object.assign(
      {
        value: field.defaultValue,
        invisible: true
      },
      field,
    );
  });
  activeFields.forEach(fieldCode => {
    partialState.fields[fieldCode] = {
      value: '',
      errorMsg: '',
    };
  });
  partialState.fields.email = {
    value: initialEmail,
  };
  return partialState;
};
export default buildWidgetState;
