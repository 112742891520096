import 'intl';
import 'intl/locale-data/jsonp/ca';
import 'intl/locale-data/jsonp/de';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/es';
import 'intl/locale-data/jsonp/fr';
import 'intl/locale-data/jsonp/it';
import 'intl/locale-data/jsonp/pt';
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import pkg from '../package.json';
import getConfig from './config';
import themeBlender from './packages/theme-blender';
import WidgetContainer from './WidgetContainer';

const render = (
  root,
  props,
  locale,
  translations = {},
  theme = {},
  config = {},
  contactTitles,
  posConfig,
) => {
  ReactDOM.render(
    <IntlProvider locale={locale} messages={translations}>
      <ThemeProvider theme={theme}>
        <WidgetContainer
          {...{
            ...props,
            locale,
            ...config,
            ...posConfig,
            contactTitles,
            captchaKey: posConfig.recaptchaSiteKey,
            currentTheme: theme.currentTheme,
          }}
        />
      </ThemeProvider>
    </IntlProvider>,
    document.querySelector(root),
  );
};

let STX_CONFIG = null;

WidgetContainer.displayName = pkg.main.match(/lib\/(.*).js/).pop();

const Widget = {
  config: config => {
    STX_CONFIG = config;
    return config;
  },
  widgets: {
    [WidgetContainer.displayName]: {
      render: async params => {
        const { props, locale, root } = params;
        const { hostname, apiURL, apiKey } = STX_CONFIG;

        const config = await getConfig(
          hostname,
          apiKey,
          apiURL,
          locale || document.documentElement.lang,
        );
        config.style = config.style || { properties: {} };

        render(
          root,
          props,
          locale || config.language,
          config.translations,
          themeBlender(config.style.properties),
          STX_CONFIG,
          config.contactTitles,
          config.configuration,
        );
      },
    },
  },
};

export default Widget;
window.STX = window.STX || {};
window.STX.widgets = Widget.widgets;
window.STX.config = Widget.config;
