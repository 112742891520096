import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import FormField from '../../../form-field';

import { contactAuthorizationType } from './type';

const ContactAuthorizationField = ({ contactAuthorizationDefinition, value, onChange }) => {
  const {
    authorizationCode,
    // externalName = '',
    dataType,
    // visibility = 'UPDATE',
    // mandatory = true,
    // rank = 1,
    invisible,
  } = contactAuthorizationDefinition;
  const onChangeInternal = evt => {
    onChange(evt.target.value);
  };
  const compId = uniqueId();
  const yesButtonID = `${compId}-yes`;
  const noButtonID = `${compId}-no`;
  return (
    !invisible && (
      <FormField label={`label.editContactProfile.authorization.${authorizationCode}`}>
        {dataType === 'CHECKBOX' && (
          <input
            type="checkbox"
            name={authorizationCode}
            value={value}
            onChange={onChangeInternal}
          />
        )}
        {dataType === 'RADIO' && (
          <div>
            <input
              id={yesButtonID}
              type="radio"
              name={authorizationCode}
              value
              checked={value === true}
              onChange={onChangeInternal}
            />
            <label htmlFor={yesButtonID}>TODO Yes</label>
            <input
              id={noButtonID}
              type="radio"
              name={authorizationCode}
              value={false}
              checked={value === false}
              onChange={onChangeInternal}
            />
            <label htmlFor={noButtonID}>TODO-No</label>
          </div>
        )}
      </FormField>
    )
  );
};

ContactAuthorizationField.propTypes = {
  contactAuthorizationDefinition: contactAuthorizationType.isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func,
};

ContactAuthorizationField.defaultProps = {
  value: true,
  dataType: 'RADIO',
  onChange: () => null,
  invisible: true,
};

export default ContactAuthorizationField;
