const color = {
  primaryButton: '#1a84f1',
  textDefault: '#3b3d40',
  textDark: '#08223c',
  textHeading: '#51595c',
  neutral: '#eff2f5',
  greyDark: '#676f7e',
  digitalBlue: '#1d73c9',
  info: '#75cdf8',
  positive: '#81dca7',
  notice: '#f8cf7e',
  warning: '#ff8c57',
  negative: '#ff4747',
};

const font = {
  family: '-apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif',
  fontWeightLight: '300',
  fontWeightRegular: '400',
  fontWeightSemibold: '500',
  fontWeightBold: '700',
  fontSizeXxl: '2.25rem',
  fontSizeXl: '1.5rem',
  fontSizeL: '1.25rem',
  fontSizeM: '1rem',
  fontSizeRegular: '0.875rem',
  fontSizeS: '0.75rem',
  fontSizeXs: '0.625rem',
  fontSizeXxs: '0.5625rem',
};

const spacing = {
  spacingXxs: '0.125rem',
  spacingXs: '0.25rem',
  spacingS: '0.5rem',
  spacingM: '1rem',
  spacingL: '1.5rem',
  spacingXl: '2rem',
  spacingXxl: '4rem',
  spaceInsetDefault: '1rem',
  spaceInsetXs: '0.25rem',
  spaceInsetS: '0.5rem',
  spaceInsetM: '1rem',
  spaceInsetL: '2rem',
  spaceInsetXl: '4rem',
  spaceStackDefault: '0 0 1rem 0',
  spaceStackXs: '0 0 0.25rem 0',
  spaceStackS: '0 0 0.5rem 0',
  spaceStackM: '0 0 1rem 0',
  spaceStackL: '0 0 2rem 0',
  spaceStackXl: '0 0 4rem 0',
  spaceDefault: '1rem',
  spaceXs: '0.25rem',
  spaceS: '0.5rem',
  spaceM: '1rem',
  spaceL: '2rem',
  spaceXl: '4rem',
};

const border = {
  borderWidthThin: '0.0625rem',
  borderWidthRegular: '0.125rem',
  borderWidthThick: '0.25rem',
  borderRadiusRegular: '0.125rem',
  borderRadiusPill: '99rem',
  borderRadiusCircle: '50%',
};

const lineHeight = {
  lineHeightHeading: '1.25',
  lineHeightText: '1.5',
  lineHeightReset: '1',
  lineHeightTab: '2.5',
  letterSpacingThight: '-0.0625rem',
  letterSpacingLoose: '0.0625rem',
};

export { color, font, spacing, border, lineHeight };
