import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

const sizes = {
  small: '1rem',
  medium: '1.5rem',
  large: '2rem',
};

const Wrapper = styled.div.attrs({
  className: props => (props.active ? 'g-ActivityIndicator is-active' : 'g-ActivityIndicator'),
})`
  position: relative;
  transform: translateZ(0);
  display: inline-block;
  vertical-align: middle;

  ${props =>
    props.size &&
    `
    height: ${sizes[props.size]};
    width: ${sizes[props.size]};
  `}

  ${props => !props.active && props.hideWhenStopped && `visibility: hidden;`}

  ${props =>
    props.active &&
    props.animation === 'spin' &&
    `
    animation: ${spin} 1.0s infinite linear;
  `}

  ${props =>
    props.active &&
    props.animation === 'pulse' &&
    `
    animation: ${pulse} 1.0s infinite linear;
  `}
`;
/** This component is used to show that things are loading. It shows a cicrle that may pulse or rotate. */
const ActivityIndicator = props => {
  const pulseSVG = (
    <svg height="100%" viewBox="0 0 32 32" width="100%">
      <circle cx="16" cy="16" fill={props.color} r="16" />
    </svg>
  );

  const spinnerSVG = (
    <svg height="100%" viewBox="0 0 32 32" width="100%">
      <circle
        cx="16"
        cy="16"
        fill="none"
        r="14"
        strokeWidth="2"
        style={{
          stroke: props.color,
          opacity: 0.2,
        }}
      />
      <circle
        cx="16"
        cy="16"
        fill="none"
        r="14"
        strokeWidth="2"
        style={{
          stroke: props.color,
          strokeDasharray: 80,
          strokeDashoffset: 60,
        }}
      />
    </svg>
  );

  return (
    <Wrapper role="progressbar" aria-valuemin="0" aria-valuemax="1" {...props}>
      {props.animation === 'spin' && spinnerSVG}
      {props.animation === 'pulse' && pulseSVG}
    </Wrapper>
  );
};

ActivityIndicator.propTypes = {
  /** Active is true when it is loading, false when the load is finished (or not yet started) */
  active: PropTypes.bool,
  /** The ActivityIndicator may be hidden or visible when it is not active */
  hideWhenStopped: PropTypes.bool,
  /** Pass the color explicitely */
  color: PropTypes.string,
  /** You can choose between three size for the loading circle */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /** Define what kind of animation you want. */
  animation: PropTypes.string,
};

ActivityIndicator.defaultProps = {
  active: true,
  hideWhenStopped: true,
  color: '#6e64b9',
  size: 'medium',
  animation: 'spin',
};

export default ActivityIndicator;
