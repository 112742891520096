import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div.attrs({
  className: 'g-Input'
})``
const StyledInput = styled.input.attrs({
  className: props => `g-Input__input stx-theme-${props.theme.currentTheme}`
})`
  width: 100%;
  box-sizing: border-box;
  padding: 0.75em 0;
  border: none;
  border-bottom: 1px solid ${props =>
    props.isError === true || props.validationErrorMsg
      ? (props.theme && props.theme.message && props.theme.message.errorColor) || '#f00'
      : '#e6e6e6'};
  outline: none;

  &.stx-theme-EVOLUTION {
    border-radius: ${props => props.theme?.inputBorderRadius || '12px'};
    border: ${props => props.theme?.inputBorderStyle || '1px solid rgba(47, 43, 67, 0.3)'};
    display: flex;
    padding: ${props => props.theme?.spacingX || '12px'};
    flex-direction: column;
    align-items: flex-start;
    gap: ${props => props.theme?.spacingX || '12px'};
    align-self: stretch;
    height: ${props => props.theme?.inputAndButtonHeight || '40px'};
    background-clip: text;
  }

  &:focus {
    border-color: ${props =>
    (props.theme && props.theme.button && props.theme.button.backgroundColor) || '#1a84f1'};
  }
`

const ErrorMessage = styled.div.attrs({
  className: props => `g-Input__error stx-theme-${props.theme.currentTheme}`
})`
  font-size: 0.8em;
  height: 1em;
  color: ${props =>
    (props.theme && props.theme.message && props.theme.message.errorColor) || '#f00'};
  &.stx-theme-EVOLUTION {
    padding: ${props => props.theme?.inputPadding || '0px 12px'};
    height: unset;
    color: #F34141;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`

const Input = props => {
  let { isError, validationErrorMsg } = props

  return (
    <Wrapper className={isError ? 'g-Input--error' : ''}>
      <StyledInput {...props} />
      <ErrorMessage className={!validationErrorMsg ? 'g-Input__error--empty' : ''}>
        {validationErrorMsg}
      </ErrorMessage>
    </Wrapper>
  )
}

Input.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  isError: PropTypes.bool,
  validationErrorMsg: PropTypes.string
}

Input.defaultProps = {
  onChange: () => {},
  isError: false,
  validationErrorMsg: '',
  value: ''
}
export default Input
