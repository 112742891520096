import PropTypes from 'prop-types';
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import styled from "styled-components";
import DropDown from '../../../dropdown';
import FormField from '../../../form-field';

const fieldCode = 'civility';

const RadioButton = styled.input.attrs({
  className: props => `stx-Radio stx-theme-${props.theme.currentTheme}`,
})`
  margin: 1px 5px;
  vertical-align: bottom;
  &.stx-theme-EVOLUTION {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    border: 1px solid rgba(47, 43, 67, 0.10);
    background: #FFF;
    gap: 12px;
    display: flex;
    align-items: center;
    margin: 0;
    &:checked:after {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      top: 0px;
      left: 0;
      position: relative;
      background-color: white;
      content: '';
      display: inline-block;
      visibility: visible;
      border: 7px solid #4499C6;
    }
  }
`;

const CivilityWrapper = styled.div.attrs({
  className: props => `stx-civility-wrapper stx-theme-${props.theme.currentTheme}`,
})`
  &.stx-theme-EVOLUTION {
    display: flex;
    align-items: flex-start;
    gap: 56px;
  }
`;

const ContactTitleWrapper = styled.div.attrs({
  className: props => `stx-contact-title-radio stx-theme-${props.theme.currentTheme}`,
})`
  &.stx-theme-EVOLUTION {
    display: flex;
    align-items: flex-start;
    gap: 12px;
  }
`;

const RadioLabel = styled.label.attrs({
  className: props => `stx-contact-title-label stx-theme-${props.theme.currentTheme}`,
})`
  &.stx-theme-EVOLUTION {
    position: relative;
    top: 3px;
  }
`;


const CivilityField = ({ value, onChange, contactTitles, currentTheme }) => (
  <FormField
    mandatory
    label={<FormattedHTMLMessage id={`widget.contactCreation.field.${fieldCode}`} />}
    key={fieldCode}>
    {currentTheme === 'EVOLUTION' && contactTitles.length <= 3 ?
      (<CivilityWrapper>
        {contactTitles.map(title => (
          <ContactTitleWrapper key={title.value}>
            <RadioButton
              id={title.value}
              name="civility"
              type="radio"
              onChange={evt => onChange(evt.target.value)}
              value={title.value}
              checked={value === title.value}
            />
            <RadioLabel htmlFor={title.value}>
              <FormattedHTMLMessage id={`widget.contactCreation.civility.${title.value}`} />
            </RadioLabel>
          </ContactTitleWrapper>
        ))}
      </CivilityWrapper>) :
      (<DropDown onSelect={evt => onChange(evt.target.value)} value={value}>
        {contactTitles.map(title => (
          <option key={title.value} value={title.value}>
            <FormattedHTMLMessage id={`widget.contactCreation.civility.${title.value}`} />
          </option>
        ))}
      </DropDown>)}
  </FormField>
);

CivilityField.FIELD_CODE = fieldCode;

CivilityField.propTypes = {
  contactTitles: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CivilityField;
