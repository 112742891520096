const initialState = {
  captchaVerifyToken: null,
  fields: {
    civility: { value: 'UNDEFINED' },
    firstName: { value: '-' },
    lastName: { value: '-' },
    email: {},
  },
  contactTitles: [
    { value: 'UNDEFINED', label: 'Undefined' },
    { value: 'MR', label: 'Mr' },
    { value: 'MRS', label: 'Mrs' },
  ],
  csrf: null,
  loading: false,
  generalMessage: '',
  criteria: {},
  authorizations: {},
};

export default initialState;
