import React, { Children } from 'react';
import PropTypes from 'prop-types';

import A from './A';
import StyledButton from './StyledButton';

const availableModifiers = ['small', 'large', 'neutral'];

/** Common component to display a clickable action */
function Button(props) {
  let className = 'g-Button';
  availableModifiers.forEach(mod => {
    if (props[mod]) {
      className += ` g-Button--${mod}`;
    }
  });

  // Render an anchor tag
  let button = (
    <A href={props.href} onClick={props.onClick} className={className} {...props}>
      {Children.toArray(props.children)}
    </A>
  );

  if (props.handleRoute || !props.href) {
    button = (
      <StyledButton onClick={props.handleRoute} className={className} {...props}>
        {Children.toArray(props.children)}
      </StyledButton>
    );
  }

  return button;
}

Button.propTypes = {
  handleRoute: PropTypes.func,
  href: PropTypes.string,
  onClick: PropTypes.func,
  /** The text or html node displayed on the button */
  children: PropTypes.node.isRequired
};

export default Button;
