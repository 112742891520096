import styled from 'styled-components';
import { color } from '../../../theme-blender/constants';

const StyledWrapper = styled.div`
  width: 100%;
  position: relative;
  outline: none;
  appearance: none;
  background-color: ${props =>
    props.theme && props.theme.content ? props.theme.content.backgroundColor : '#fff'};
  color: ${props => (props.theme && props.theme.content ? props.theme.content.color : '#3b3d40')};
  ${props => (props.theme && props.theme.currentTheme === 'EVOLUTION' && `
    color: #2F2B43;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-radius: 20px;
    sup {
      color: #000;
      position: relative;
      top: 5px;
      left: 2px;
      font-size: 16px;
      line-height: 12px;
      letter-spacing: -0.16px;
    }
  `)}
`;

const CenterAlignWrapper = styled.div`
  text-align: center;
  padding: 0.5em;
  ${props => props.theme.currentTheme === 'EVOLUTION' && `
    text-align: right;
    padding: 6px 3px;
    a {
      text-decoration: none;
      color: #000;
    }
  `}
`;

const MessageDialogWrapper = styled.div`
  padding: 0.5em;
  background: white;
`;

const ButtonContainer = styled.div`
  padding: 0.5em;
  text-align: center;
`;
const MaskedContentWrapper = styled.div``;
const LegalNoticeContainer = styled.div`
  padding: 0.5em;
  font-size: 0.85em;
  color: ${color.greyDark};
  text-align: justify;
`;

export {
  ButtonContainer, CenterAlignWrapper, LegalNoticeContainer, MaskedContentWrapper, MessageDialogWrapper, StyledWrapper
};

