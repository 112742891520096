import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`

const Backdrop = styled.div`
  position: absolute;
  z-index: ${props => props.zIndex};
  width: 100%;
  height: 100%;
  background: white;
  opacity: ${props => (props.opacity ? props.opacity : 0.9)};
`

const ContentWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${props => props.zIndex};
`

const VerticalAlignWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  text-align: initial;
`

const CenterAlignWrapper = styled.div`
  margin: 0 auto;
  height: 100%;
  text-align: center;
  &::before {
    content: " ";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
`

const Dialog = ({ open, children, backdrop, zIndexBase }) => {
  if (open) {
    return (
      <Container className="g-Dialog">
        <Backdrop className="g-Dialog__backdrop" {...backdrop} zIndex={zIndexBase} />
        <ContentWrapper zIndex={zIndexBase + 1}>
          <CenterAlignWrapper>
            <VerticalAlignWrapper className="g-Dialog__content-container">
              {children}
            </VerticalAlignWrapper>
          </CenterAlignWrapper>
        </ContentWrapper>
      </Container>
    )
  } else {
    return null
  }
}

Dialog.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
  zIndexBase: PropTypes.number,
  backdrop: PropTypes.shape({
    opacity: PropTypes.number 
  })
}

Dialog.defaultProps = {
  open: false,
  zIndexBase :100,
  backdrop: {}
}

export default Dialog
