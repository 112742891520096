import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../../../dialog';
import ActivityIndicator from '../../../activity-indicator';

const OverlayUi = ({ showActivityIndicator, children }) => {
  let content;

  if (showActivityIndicator) {
    content = <ActivityIndicator animation="pulse" />;
  } else if (children) {
    content = children;
  }

  if (content) {
    return (
      <Dialog open backdrop={{ opacity: 1 }}>
        {content}
      </Dialog>
    );
  }
  return null;
};

OverlayUi.propTypes = {
  showActivityIndicator: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

OverlayUi.defaultProps = {
  showActivityIndicator: false,
};
export default OverlayUi;
