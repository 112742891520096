import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import { MessageDialogWrapper, ButtonContainer } from './styles';
import Button from '../../../button';
import Dialog from '../../../dialog';

const Messages = ({ showButton, mergeState, children }) => (
  <Dialog open backdrop={{ opacity: 1 }}>
    <MessageDialogWrapper>
      {children}
      {showButton && (
        <ButtonContainer>
          <Button
            onClick={() =>
              mergeState({
                generalMessage: {
                  show: false,
                },
              })
            }>
            <FormattedHTMLMessage id="widget.contactCreation.button.close" />
          </Button>
        </ButtonContainer>
      )}
    </MessageDialogWrapper>
  </Dialog>
);
Messages.propTypes = {
  showButton: PropTypes.bool.isRequired,
  mergeState: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Messages;
