import { addLocaleData } from 'react-intl';
import caLocaleData from 'react-intl/locale-data/ca';
import deLocaleData from 'react-intl/locale-data/de';
import enLocaleData from 'react-intl/locale-data/en';
import esLocaleData from 'react-intl/locale-data/es';
import frLocaleData from 'react-intl/locale-data/fr';
import itLocaleData from 'react-intl/locale-data/it';
import ptLocaleData from 'react-intl/locale-data/pt';
import nlLocaleData from "react-intl/locale-data/nl";

import caTranslationMessages from './translations/ca.json';
import deTranslationMessages from './translations/de.json';
import enTranslationMessages from './translations/en.json';
import esTranslationMessages from './translations/es.json';
import frTranslationMessages from './translations/fr.json';
import itTranslationMessages from './translations/it.json';
import ptTranslationMessages from './translations/pt.json';
import nlTranslationMessages from "./translations/nl.json";

addLocaleData(enLocaleData);
addLocaleData(frLocaleData);
addLocaleData(deLocaleData);
addLocaleData(itLocaleData);
addLocaleData(esLocaleData);
addLocaleData(caLocaleData);
addLocaleData(ptLocaleData);
addLocaleData(nlLocaleData);

const translationMessages = {
  en: enTranslationMessages,
  fr: frTranslationMessages,
  de: deTranslationMessages,
  it: itTranslationMessages,
  es: esTranslationMessages,
  ca: caTranslationMessages,
  pt: ptTranslationMessages,
  nl: nlTranslationMessages,
};

export default locale => translationMessages[locale];
